import { ArrowUpOutlined } from "@ant-design/icons";
import handleBackTop from "../../helper/handleBackTop";

export default function FooterCustomer() {
  return (
    <div className="footer">
      <div className="link-footer">
        <a href="#" target="_blank">
          COMPANY
        </a>
        <a href="#" target="_blank">
          CONTACT
        </a>
      </div>
      <div className="btn-back-top" onClick={handleBackTop}>
        <ArrowUpOutlined className="icon-arrow" />
        <p>Page Top</p>
      </div>
    </div>
  );
}
