import { Button, Checkbox, Form, Input, Radio, Space } from "antd";
import { DataPayment, paymentApi } from "../../../api/paymentApi/paymentApi";
import { useLocation, useParams } from "react-router-dom";
import "./payment.scss";
import overLoader from "../../../components/overLoader";
import { useState } from "react";

export const PaymentView = () => {
  const { order_id } = useParams();
  const { state } = useLocation();
  const [statusLoader, setStatusLoader] = useState(false);

  const converFormatPrice = () => {
    const temp = state.totalPayment?.slice(1) || 0;
    const convertNum = temp.replace(",", "");
    return Number(convertNum.trim());
  };

  const onFinish = async (dataPayment: DataPayment) => {
    setStatusLoader(true);
    try {
      const resData: any = await paymentApi.addPayment({
        ...dataPayment,
        order_id: Number(order_id),
        total_payment: converFormatPrice(),
        // payment_method: Number(dataPayment.payment_method),
        payment_method: 2,
      });

      if (resData.status === "success") {
        setStatusLoader(false);
        if (resData.data.url_stripe) {
          return (window.location.href = resData.data.url_stripe);
        } else {
          return (window.location.href = "/payment-success");
        }
      }
    } catch (err) {
      console.log("Fail.");
    }
  };

  return (
    <section className="payment">
      {statusLoader ? overLoader() : ""}
      <div className="payment-wrap">
        <h3>購入者情報 入力画面</h3>
        <Form
          onFinish={onFinish}
          labelCol={{
            xs: { span: 24 },
            sm: { span: 12 },
            md: { span: 8 },
            lg: { span: 8 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 12 },
            md: { span: 12 },
            lg: { span: 12 },
          }}
        >
          <Form.Item
            label="姓"
            name="last_name"
            rules={[{ required: true, message: "データを入力して下さい。" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="名"
            name="first_name"
            rules={[{ required: true, message: "データを入力して下さい。" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="姓(よみがな)"
            name="last_name_furigana"
            rules={[{ required: true, message: "データを入力して下さい。" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="名(よみがな)"
            name="first_name_furigana"
            rules={[{ required: true, message: "データを入力して下さい。" }]}
          >
            <Input />
          </Form.Item>

          {/* <Form.Item
            label="姓(国または地域)"
            name="country"
            rules={[{ required: true, message: "データを入力して下さい。" }]}
          >
            <Input />
          </Form.Item> */}
          <Form.Item
            label="郵便番号"
            name="zipcode"
            rules={[{ required: true, message: "データを入力して下さい。" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="都道府県"
            name="prefecture"
            rules={[{ required: true, message: "データを入力して下さい。" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="市区町村"
            name="city"
            rules={[{ required: true, message: "データを入力して下さい。" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="番地"
            name="address"
            rules={[{ required: true, message: "データを入力して下さい。" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="メールアドレス"
            name="email"
            rules={[{ required: true, message: "データを入力して下さい。" }]}
          >
            <Input />
          </Form.Item>

          {/* <Form.Item
            name="checkbox_form"
            valuePropName="checked"
            rules={[{ required: true, message: "データを入力して下さい。" }]}
          >
            <Checkbox>別の住所へ配送しますか?</Checkbox>
          </Form.Item> */}

          <Form.Item
            label="注文メモ(オプション)"
            name="notes"
            // rules={[{ required: true, message: "データを入力して下さい。" }]}
          >
            <Input />
          </Form.Item>

          {/* <p className="payment-method-title">決済方法</p>
          <div>
            <Form.Item
              name="payment_method"
              rules={[{ required: true, message: "データを入力して下さい。" }]}
            >
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value="1">代金引換 代引き手数料無料</Radio>
                  <Radio value="2">クレジットカード</Radio>{" "}
                </Space>
              </Radio.Group>
            </Form.Item>
          </div> */}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "10px",
            }}
          >
            <Form.Item>
              <Button type="primary" htmlType="submit">
                購入する
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </section>
  );
};
