/* Total price sub item with N */

const totalPriceSubStyle = (n: number, listSubItemActive: any) => {
  /* Filter sub item by group */
  const temp = listSubItemActive.reduce((group: any, item: any) => {
    const { parent_id } = item;
    group[parent_id] = group[parent_id] ?? [];
    group[parent_id].push(item);
    return group;
  }, {});

  /* Convert list sub item to array */
  const listSubItemArr = Object.values(temp);

  /* Filter with conditon N */
  console.log("N: ", n);
  
  const temp2: any = [];
  listSubItemArr.forEach((item: any) => {
    if (item.length >= n) {
      const handleItem = item.filter((val: any) => val.priceSub !== 0);
      const handleArr = handleItem.sort((a: any, b: any) => a.priceSub - b.priceSub); /* Sort price */
      handleArr.forEach((data: any, index: number) => {
        if (index + 1 >= n) {
          temp2.push(data);
        }
      });
    }
  });

  /* Handle total price */
  let totalPrice = 0;
  temp2.forEach((item: any) => totalPrice += item.priceSub)


  return totalPrice;
};

export default totalPriceSubStyle;
